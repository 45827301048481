#homepage {
    h1 {
        margin-top: 8vh;
    }
}

.interior-page {
    padding-top: 6rem;
}

.page-title {
    text-align: center;
    margin-bottom: 30px;
}

.card {
    margin-bottom: 30px;
}

.list-group-flush {
    margin-left: 0;
}
