* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Document level adjustments */
html {
  font-size: 18px;
}
@media (max-width: 991px) {
  html { font-size: 16px; }
}
@media (max-width: 565px) {
  html { font-size: 14px; }
}

/* Type will scale with document */
h1 {
  font-size: 3rem;
  font-family: $header-font-family;
}
h2 {
  font-size: 2.5rem;
  font-family: $header-font-family;
}
h3 {
  font-size: 2rem;
  font-family: $header-font-family;
}

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font-weight: $base-font-weight;
    font-family: $base-font-family;
    line-height: $base-line-height;
    // font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
}

body {
    // min-height: 100vh;
    // background-color: #DCD9D4;
    // background-image:
    //     linear-gradient(to bottom, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%),
    //     radial-gradient(at 50% 0%, rgba(255,255,255,0.10) 0%, rgba(0,0,0,0.50) 50%);
    // background-blend-mode: soft-light,screen;
}

// .body-background {
//     position: absolute;
//     top: 0;
//     height: 100vh;
//     width: 100%;
//     filter: blur(140px);
//     background-color: transparent;
//     background:
//         linear-gradient(-173deg, rgba(255,255,255,0.20) 0%, #000000 100%),
//         linear-gradient(72deg, rgba(255,255,255,0.25) 25%, rgba(0,0,0,0.25) 100%),
//         radial-gradient(47% 102%, rgba(255,255,255,0.50) 0%, rgba(21,24,32,0.60) 120%),
//         url('../img/contemplation.jpg');
//     background-size: cover;
//     background-position: center;
//     background-blend-mode: screen;
// }



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

ul.nav {
    margin-left: 0;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: $base-font-weight;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    // &:visited {
    //     color: darken($brand-color, 15%);
    // }

    &:hover {
        color: $text-color;
        text-decoration: underline;
    }
}

a,
a:hover,
a:focus,
a:active,
button,
button:hover,
button:focus,
button:active {
    @include transition(all 0.3s ease-in-out);
}

.button:hover,
.button:focus,
.button:active {
    @include transition(all 0.3s ease-in-out);
    text-decoration: none;
}


/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: #eef;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}



.full-height {
    height: 100vh;
}
