.site-header {
    z-index: 100;
    position: fixed;
    width: 100%;
    .navbar {
        padding: .5rem 0;
    }
    .navbar-brand {
        font-size: 1.6rem;
    }
    .navbar-brand,
    .nav-link {
        color: white;
        &:hover,
        &:focus,
        &:active {
            color: $light-blue;
        }
    }
    // &.is-active {
    //     background-color: rgba(#797572, 0.99);
    // }
}

body:not(#homepage) .site-header {
    background-color: rgba(#797572, 0.98);
}

#main-nav {
    z-index: 101;
}

#mobile-nav {
    z-index: 100;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(#797572, 0.98);

    visibility: hidden;
    opacity: 0;
    @include transition(all 0.3s ease-in-out);
    &.visible {
        visibility: visible;
        opacity: 1;
        @include transition(all 0.3s ease-in-out);
    }
    #navContents {
        width: 100%;
    }
    .nav-item {
        float: none;
        margin: 10px 0;
    }
    .nav-link {
        font-size: 1.6rem;
        text-align: center;
    }
}

.navbar-toggler {
    width: 50px;
    height: 40px;
    padding: 8px 10px;
    margin-top: 2px;
}

.hamburger {
    margin-right: -10px;
}

.hamburger-box {
    width: 30px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 30px;
    height: 3px;
    background-color: white;
}

.hero {
    color: white;
    max-height: 100vh;
    @include bgimagecover(transparent, '', center);
    .overlay {
        background-color: rgba(#797572, 0.5);
        background-image: -webkit-linear-gradient(263deg, rgba(255, 255, 255, 0.2) 0%, #000000 100%), -webkit-linear-gradient(18deg, rgba(255, 255, 255, 0.25) 25%, rgba(0, 0, 0, 0.25) 100%), -webkit-radial-gradient(47% 102%, rgba(255, 255, 255, 0.5) 0%, rgba(21, 24, 32, 0.6) 120%);
        background-image: linear-gradient(-173deg, rgba(255, 255, 255, 0.2) 0%, #000000 100%), linear-gradient(72deg, rgba(255, 255, 255, 0.25) 25%, rgba(0, 0, 0, 0.25) 100%), radial-gradient(47% 102%, rgba(255, 255, 255, 0.5) 0%, rgba(21, 24, 32, 0.6) 120%);
        background-blend-mode: multiply;
    }
    .btn {
        margin-top: 15px;
    }
    @media (max-width: 565px) {
        h1 {
            font-size: 2.6rem;
        }
    }
}
