/*
 * Collapse Reverse
 */
.hamburger--collapse-r {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.15s;
    transition-delay: 0.15s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::after {
      top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
      transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                  opacity 0.1s linear;
    }

    &::before {
      transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                  transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(45deg);
      transition-delay: 0.32s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::after {
        top: 0;
        opacity: 0;
        transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    opacity 0.1s 0.27s linear;
      }

      &::before {
        top: 0;
        transform: rotate(90deg);
        transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
