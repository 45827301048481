.btn {
    font-weight: bold;
    @include transition(all 0.3s ease-in-out);
    &:hover,
    &:focus,
    &:active {
        @include transition(all 0.3s ease-in-out);
    }
}

.btn-ghost {
    color: white;
    border: 2px solid white;
    &:hover,
    &:focus,
    &:active {
        color: #797572;
        background: white;
    }
}
