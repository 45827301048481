/*-------------------------------------------------------*\
    Variables
/*-------------------------------------------------------*/

//== Colors
//
//## Colors for use across site.

$text-color: #69737b;

$dark-blue: #27498C;
$light-blue: #56B4D7;
$green: #97A626;
$yellow: #F2BE7E;
$red: #A62E2E;
